:root {
  --gray-color: #718195;
  --orange-color: #FF861B;
  --red-color: #FF564B;
  --green-color: #71D300;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

.blue-text{
  color: #089EDF;
}
.text-orange {
  color: var(--orange-color);
}

.text-red {
  color: var(--red-color)
}

.text-green {
  color: var(--green-color)
}

.text-gray {
  color: var(--gray-color)
}

.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 87vh;
  overflow-y: scroll;
  width: 100%;
  margin-top: 13vh;
  margin-left: 147px;
}

.home-info-container {
  display: grid;
  grid-template-columns: 3fr 4fr;
  column-gap: 40px;
}

.disabled {
  cursor: not-allowed !important;
}