.App {
  text-align: center;
  font-family: 'Raleway', sans-serif;
}

.txt-weight-bold {
  color: var(--gray-color);
  font-weight: bold;
  font-family: 'Raleway', sans-serif;
}

.txt-weight-normal {
  color: var(--gray-color);
  font-family: 'Raleway', sans-serif;
}

.bg-orange {
  background-color: #FF861B;
}

.text-orange {
  color: #FF861B;
}

.upload-file-modal {
  display: grid;
  grid-template-columns: 1fr 3fr;
  column-gap: 20px;

}


.delete-item {
  position: absolute;
  right: -12px;
  top: -12px;
  height: 30px;
  width: 30px;
  background-color: white;
  border-radius: 100px;
  padding: 0;
}

.thumbnails .thumbnail-container:not(:first-child) {
  margin-left: 30px;
}

.thumbnail-container {
  height: fit-content;
  width: fit-content;
  position: relative;

}

.thumbnail {
  height: 130px;
  width: 120px;
  object-fit: cover;


}

.btn-bd-orange {
  --bs-btn-font-weight: 600;
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: var(--orange-color);
  --bs-btn-border-color: var(--bd-violet);
  --bs-btn-border-radius: .5rem;
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: var(--orange-color);
  --bs-btn-hover-border-color: #{shade-color($bd-violet, 10%)};
  --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #{shade-color($bd-violet, 20%)};
  --bs-btn-active-border-color: #{shade-color($bd-violet, 20%)};
}


.custom_modal {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.75) !important;
  display: none;
  justify-content: center;
  align-items: center;
  /* border-radius: ; */
}

.custom_modal.isOpen {
  display: flex;
}

.custom_modal_close {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.custom_modal__content {
  position: relative;
  min-height: 200px;
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;
  border-radius: 5px;
}

.custom_modal__header {
  display: flex;
  justify-content: space-between;  
  align-items: center;
  background-color: white;
  padding: 0rem 1rem;
  position: sticky;
  top: 0;
}



.custom_modal__footer{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 999;
  padding: 1.2rem;
  gap: 1rem;
}

